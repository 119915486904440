import * as React from 'react'
import { Provider, Root, Trigger, Arrow, Content } from '@radix-ui/react-tooltip'

export const TooltipVariants = {
  default: 'var(--primary)',
  warning: '#ff0000',
  success: 'var(--success)',
  notice: 'var(--warning)',
}

const DefaultToolTipStyles = {
  backgroundColor: 'white',
  color: 'black',
  zIndex: 99,
  border: '1px solid var(--primary)',
  boxShadow: 'rgba(0, 0, 0, 0.36) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
}

const TooltipContent = React.forwardRef(
  (
    {
      className = 'rounded p-3',
      side = 'top',
      align = 'center',
      sideOffset = 3,
      alignOffset = 0,
      width = 500,
      variant = 'default',
      ...props
    },
    ref
  ) => {
    const variantStyles = {
      default: DefaultToolTipStyles,
      warning: {
        ...DefaultToolTipStyles,
        color: TooltipVariants[variant],
        border: `1px solid ${TooltipVariants[variant]}`,
      },
      success: {
        ...DefaultToolTipStyles,
        border: `1px solid ${TooltipVariants[variant]}`,
      },
      notice: {
        ...DefaultToolTipStyles,
        border: `1px solid ${TooltipVariants[variant]}`,
      },
    }
    return (
      <Content
        ref={ref}
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        className={className}
        style={{ ...variantStyles[variant], width: width }}
        {...props}
      />
    )
  }
)
TooltipContent.displayName = Content.displayName

const Tooltip = ({
  className,
  side,
  sideOffset,
  align,
  alignOffset,
  width,
  tooltipTrigger,
  tooltipContent,
  variant = 'default',
}) => (
  <Provider delayDuration={150}>
    <Root>
      <Trigger asChild>{tooltipTrigger}</Trigger>
      {tooltipContent && (
        <TooltipContent
          className={className}
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          width={width}
          variant={variant}
        >
          <Arrow fill={TooltipVariants[variant]} />
          {tooltipContent}
        </TooltipContent>
      )}
    </Root>
  </Provider>
)

export default Tooltip
