import { useRef, useState } from 'react'
import { Dialog as PrimeReactDialog } from 'primereact/dialog'
import { Help } from './Icons'

interface DialogProps {
  title: string | JSX.Element
  show: boolean
  closeClickHandler: () => void
  documentationUrl?: string
  isMaximizable?: boolean
  customMaxWidth?: string
  customStyles?: object
  forceMount?: boolean
  isModal?: boolean
  children: JSX.Element
}

const dialogStyle = () => ({
  height: '100%',
  width: '100%',
  borderRadius: 6,
  backgroundColor: '#fff',
})

// https://primereact.org/dialog/
const Dialog: React.FC<DialogProps> = ({
  title,
  show,
  closeClickHandler,
  documentationUrl,
  isMaximizable = true,
  customStyles = {},
  customMaxWidth,
  forceMount = false,
  isModal = true,
  children,
}) => {
  const dialogRef = useRef(null)
  const [maxWidth, setMaxWidth] = useState(customMaxWidth ?? '800px')
  const [maxHeight, setMaxHeight] = useState('90vh')

  return (
    <PrimeReactDialog
      ref={dialogRef}
      // Manual handling to keep Dialog mounted when required
      visible={forceMount ? true : show}
      modal={forceMount ? show : isModal}
      className={forceMount ? `${show ? 'd-flex' : 'd-none'}` : null}
      maskClassName={forceMount ? `${show ? 'd-flex' : 'd-none'}` : null}
      blockScroll={forceMount ? show : true}
      onHide={() => closeClickHandler()}
      style={{ ...dialogStyle(), ...customStyles, maxWidth, maxHeight }}
      dismissableMask={true}
      maximizable={isMaximizable}
      onMaximize={() => {
        // Reset position to center
        dialogRef.current.resetPosition()
        // Adjust max width and height
        setMaxWidth(maxWidth === '100vw' ? customMaxWidth ?? '800px' : '100vw')
        setMaxHeight(maxHeight === '90vh' ? '100vh' : '90vh')
      }}
      header={
        <h5 className="d-flex align-items-center mb-0">
          {title}
          {documentationUrl && (
            <a
              href={documentationUrl}
              target="_blank"
              className="ml-1 text-success d-flex align-items-center"
            >
              {/* @ts-ignore */}
              <Help size="25px" />
            </a>
          )}
        </h5>
      }
    >
      {children}
    </PrimeReactDialog>
  )
}

export default Dialog
